.scrollbar::-webkit-scrollbar {
  appearance: none;
  width: 7px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: theme(colors.transparent);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: theme(colors.black / 50%);
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: theme(colors.black / 50%);
}

/* scrollbar when element is hovered */
.scrollbar-dark:hover::-webkit-scrollbar-thumb {
  background-color: theme(colors.white / 50%);
}

/* scrollbar when scrollbar is hovered */
.scrollbar-dark::-webkit-scrollbar-thumb:hover {
  background-color: theme(colors.white / 50%);
}
