.ag-theme-mondra,
.ag-theme-mondra-secondary .ag-root-wrapper {
  border: solid 1px;
  border-color: transparent;
}

.ag-theme-mondra,
.ag-theme-mondra-secondary .ag-header {
  border-bottom: solid 1px var(--ag-row-border-color, var(--ag-gray-border-color, #dde2eb));
}

.ag-theme-alpine.ag-theme-mondra {
  --ag-odd-row-background-color: #fff;
  --ag-row-hover-color: #fff;
  --ag-font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  @apply font-inter;
}
.ag-theme-mondra {
  --ag-selected-row-background-color: #ecf9f8 !important;
}
.ag-theme-mondra .ag-root-wrapper {
  @apply border-none;
}
.ag-theme-mondra .ag-header-row {
  @apply !text-sm !font-medium !text-coolGray-600;
}
.ag-theme-mondra .ag-header {
  @apply border-none bg-gray-200;
}
.ag-theme-mondra .ag-header-cell {
  @apply !px-4;
}

.ag-theme-mondra .ag-cell {
  @apply border-none !px-4 leading-11;
}

.ag-theme-mondra .ag-row-selected {
  @apply bg-white;
}
.ag-theme-mondra .ag-paging-pane {
  @apply sticky bottom-0;
}

.ag-theme-mondra .ag-p-0 {
  @apply p-0;
}

.ag-theme-mondra .ag-center-header {
  @apply text-center;
}
.ag-theme-mondra .ag-right-header {
  @apply flex items-center justify-end;
}
.ag-theme-mondra .ag-right-header .ag-header-cell-label {
  @apply items-center justify-end text-end;
}

.ag-theme-mondra .ag-center-header .ag-header-cell-label {
  @apply justify-center text-center;
}
.ag-theme-mondra .ag-center-cell {
  @apply flex items-center justify-center text-center;
}
.ag-theme-mondra .ag-right-cell {
  @apply flex items-center justify-end;
}
.ag-theme-mondra .ag-react-container {
  @apply w-full px-0;
}

.ag-theme-mondra .ag-overlay-loading-wrapper {
  background: none;
}
.ag-theme-mondra .ag-overlay-wrapper {
  @apply flex items-start justify-start pt-12;
}

.ag-theme-mondra .ag-full-width-container .ag-row-loading {
  @apply border-none;
}
.ag-theme-mondra .ag-overlay-no-rows-center {
  @apply p-4;
}

/** Mondra AG Grid Secondary**/
.ag-theme-mondra.ag-theme-mondra-secondary .ag-header {
  @apply border-b border-solid bg-white;
}

.ag-theme-mondra-secondary .ag-header-cell {
  @apply bg-white;
}

.ag-theme-mondra-secondary .ag-header-cell[aria-sort='ascending'],
.ag-theme-mondra-secondary .ag-header-cell[aria-sort='descending'] {
  @apply bg-gray-100;
}

.ag-theme-mondra.ag-theme-mondra-secondary .ag-header-row {
  @apply text-xs font-normal text-coolGray-600;
}

.ag-theme-mondra-secondary .ag-header-cell-sorted-asc,
.ag-theme-mondra-secondary .ag-header-cell-sorted-desc {
  @apply font-medium text-primary;
}

.ag-theme-mondra.ag-theme-mondra-secondary .ag-row {
  @apply border-none;
}

.ag-theme-mondra.ag-theme-mondra-secondary .ag-cell.agSparkBarAxisBorder {
  @apply border-r-2 border-gray-300;
}
.ag-theme-mondra.ag-theme-mondra-secondary .ag-cell {
  @apply border-r-0;
}

.ag-theme-mondra .ag-cell-wrapper.ag-row-group {
  @apply items-center;
}
.ag-theme-mondra.ag-theme-mondra-secondary .ag-group-value {
  @apply flex h-12 items-center;
}
/** Mondra AG Grid Secondary**/
.ag-theme-mondra-secondary .ag-group-contracted {
  @apply h-12;
}

/** Mondra AG Grid v2 **/
.ag-theme-alpine.ag-theme-mondra-v2 {
  --ag-header-height: 36px;
  --ag-row-height: 40px;
  --ag-foreground-color: #161616;
  --ag-data-color: #161616;
  --ag-background-color: #ffffff;
  --ag-borders: 1px solid;
  --ag-border-color: #c6c6c6;
  --ag-borders-secondary: 1px solid;
  --ag-secondary-border-color: #c6c6c6;
  --ag-row-border-color: #c6c6c6;
  --ag-header-column-resize-handle-display: none;
  --ag-row-hover-color: #e8e8e8;
  --ag-header-background-color: #f4f4f4;
  --ag-header-foreground-color: #161616;
  --ag-font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --ag-icon-font-weight: 500;
  --ag-alpine-active-color: #161616;
  --ag-control-panel-background-color: #ffffff;
  --ag-input-focus-border-color: none;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #c6c6c6;

  @apply font-inter;
}

.ag-theme-mondra-v2 .ag-root-wrapper {
  @apply border-y-0;
}

/* Remove borders for hidden sidebar */
.ag-theme-mondra-v2 .ag-side-bar-right {
  @apply !border-none;
}

.ag-theme-mondra-v2 .ag-header-group-text,
.ag-header-cell-comp-wrapper {
  @apply text-xs font-medium text-primary;
}

.ag-theme-mondra-v2 .ag-cell {
  @apply flex min-w-0 items-center whitespace-pre-wrap !leading-5;
}

.ag-theme-mondra-v2 .ag-list {
  @apply z-dialog;
}

.ag-theme-mondra-v2 .ag-tool-panel-wrapper {
  @apply fixed inset-y-0 bottom-[72px] right-0 top-14 z-dialog mt-14 flex translate-x-0 border-y border-gray-300 shadow-xl transition duration-500 ease-in-out sm:duration-700;
}

.ag-theme-mondra-v2 .ag-tool-panel-wrapper.ag-hidden {
  @apply !flex translate-x-full;
}

.ag-theme-mondra-v2 .ag-column-select-header-checkbox,
.ag-column-select-header-icon,
.ag-tool-panel-horizontal-resize,
.ag-filter-toolpanel-search,
.ag-side-buttons {
  @apply !hidden;
}

.ag-theme-mondra-v2 .ag-center-cell {
  @apply flex items-center justify-center text-center;
}

.ag-theme-mondra-v2 .ag-right-cell {
  @apply flex items-center justify-end;
}

.ag-theme-mondra-v2 .ag-center-header .header-content {
  @apply justify-center text-center;
}

/* Remove dashed border ad margins from specific filter */
.ag-theme-mondra-v2 .ag-filter-toolpanel-instance-filter {
  @apply !m-0 !border-none;
}

.ag-theme-mondra-v2 .ag-radio-button-input-wrapper:focus-within {
  @apply shadow-none;
}

.ag-theme-mondra-new .ag-radio-button-input-wrapper.ag-checked::after {
  @apply text-coolGray-700;
}

.ag-theme-mondra-v2 .ag-filter-list-panel {
  @apply !p-4;
}

.ag-theme-mondra-v2 .ag-group {
  @apply !border-x border-gray-300;
}

.ag-theme-mondra-v2 .ag-last-group-visible {
  @apply border-b border-gray-300;
}

.ag-theme-mondra-v2 .ag-column-select-virtual-list-viewport {
  @apply p-0;
}

.ag-theme-mondra-v2 .ag-column-select {
  @apply overflow-auto border-none;
}

.ag-theme-mondra-v2 .ag-column-select-list {
  @apply m-4 overflow-visible border border-gray-300;
}

.ag-theme-mondra-v2 .ag-column-select-virtual-list-container {
  @apply my-2;
}

.ag-theme-mondra-v2 .ag-column-select-header,
.ag-column-panel-column-select {
  @apply border-none;
}

.ag-theme-mondra-v2 .ag-column-select-header {
  @apply px-4 pt-4;
}

.ag-theme-mondra-v2 .ag-checkbox-input-wrapper {
  @apply flex;
}

/* Header text font weight */
.ag-theme-mondra-v2 .ag-filter-toolpanel-group-title {
  @apply font-normal;
}

/* Remove listeners from dropdown elements to prevent closing sidebar */
.ag-theme-mondra-v2 .ag-list-item > span {
  @apply pointer-events-none;
}

.ag-theme-mondra-v2 .ag-header-cell-resize::after {
  @apply !top-0 !h-full !bg-red-200;
}

/* Fix right pinned col */
.ag-theme-mondra-v2 .ag-pinned-right-cols-container,
.ag-center-cols-container {
  margin-right: 0 !important;
}
.ag-theme-mondra-v2 .ag-header-row .ag-header-cell:last-child::after {
  @apply !h-0;
}

/* Ingredients table */
.ag-theme-mondra-v2.ingredients-table .align-center .ag-header-cell-label {
  @apply justify-center;
}

.ag-theme-mondra-v2.ingredients-table .ag-header-cell-resize {
  @apply cursor-default;
}

.ag-theme-mondra-v2.ingredients-table .ag-row {
  @apply !border-b-0 !border-none;
}

.ag-theme-mondra-v2.ingredients-table .ag-root-wrapper {
  @apply border-x-0;
}

.ag-right-aligned-header.ag-header-cell:not(.ag-header-cell-auto-height)
  .ag-header-cell-comp-wrapper {
  @apply justify-end;
}
