@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-day-picker/dist/style.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'github-markdown-css/github-markdown-dark.css';
@import 'github-markdown-css/github-markdown.css' print;
@import './react-day-picker.css';
/* @media print {
	@import 'github-markdown-css/github-markdown-dark.css'
} */

@import './scrollbar.css';

@layer components {
  @import './grid.css';

}

@layer base {
	.markdown-body a {
		@apply !text-brand
	}

}

@layer utilities {
  * {   --tw-clip-polygon: var(--tw-clip-polygon-a), var(--tw-clip-polygon-b), var(--tw-clip-polygon-c), var(--tw-clip-polygon-d);
  --tw-clip-polygon-a: 0 calc(100% * (1 - var(--tw-clip-start)));
  --tw-clip-polygon-b: 100% calc(100% * (1 - var(--tw-clip-end)));
  --tw-clip-polygon-c: 100% 100%;
  --tw-clip-polygon-d: 0 100%;
  }
  .clip-polygon.area-1{
		--tw-clip-start: 0;
		--tw-clip-end: 0.6;
	}
	.clip-polygon.area-2{
		--tw-clip-start: 0.6;
		--tw-clip-end: 0.3;
	}
	.clip-polygon.area-3{
		--tw-clip-start: 0.3;
		--tw-clip-end: 0.8;
	}
	.clip-polygon.area-4{
		--tw-clip-start: 0.8;
		--tw-clip-end: 0;
	}
  .clip-polygon {
    clip-path: polygon(var(--tw-clip-polygon));
  }
}



input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
.inter-tnum {
  font-family: 'Inter var', sans-serif;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

.grid-search {
  @apply z-10 !border-primary !shadow-none
}

.grid-search-with-filter {
  @apply z-10 !border-primary [&>div>button]:mr-24 !shadow-none
}

.grid-search-input {	
  @apply w-11/12 text-sm
}

.grid-search-filter-button {
  @apply absolute right-1 z-20
}

.search-input {
  @apply m-0 !border-0 !shadow-none !ring-0 h-14
}


.wizard-width {
  @apply min-w-2xl max-w-4.5xl xl:max-w-7xl;
}

/* FormFieldInline */
div#description[contenteditable=false]:empty:before{
  content: 'Enter a description for this scenario here';
  pointer-events: none;
  display: block;
}

/*ASAP widget open close */
button.zd-launcher-close__app.data-theme-asap-launcher-custom.zd_asap_launcher_close{
	@apply !hidden
}
button.zd-launcher-open.zd_asap_launcher_active.data-theme-asap-launcher-custom {
	@apply !inline-flex
}