.rdp {
  --rdp-cell-size: 35px;
  --rdp-caption-font-size: theme('fontSize.sm');
}

.rdp-caption_label {
  @apply font-medium;
}

.rdp-head_cell {
  @apply h-[30px] w-9 text-xxs font-semibold;
}

.rdp-cell {
  @apply px-0 pb-1 pt-0;
}
.rdp-day {
  @apply text-xs font-normal;
}
.rdp-cell,
.rdp-day {
  @apply h-8 w-9;
}

.rdp-day_selected,
.rdp-day_selected:hover {
  @apply bg-primary-500;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  @apply bg-primary-100;
}

.dark .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  @apply bg-primary-700;
}

.rdp-button:focus-visible:not([disabled]) {
  @apply border-2 border-primary-500 bg-primary-100;
}

.dark .rdp-button:focus-visible:not([disabled]) {
  @apply bg-transparent;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  @apply border-2 border-primary-500 bg-primary-100;
}

.dark .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  @apply bg-transparent;
}

.dark .rdp-dropdown {
  @apply bg-coolGray-600 p-2;
}

.rdp-day_selected.rdp-day_range_start {
  @apply rounded-l-lg;
}

.rdp-day_selected.rdp-day_range_end {
  @apply rounded-r-lg;
}
